<template>
  <b-card title="菲乐兰奶粉溯源信息">
    <app-timeline>

      <!-- 12 INVOICES HAVE BEEN PAID -->
      <app-timeline-item icon="MapPinIcon">
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ $t('CollectionPerson') }}</h6>
          <small class="text-muted">{{ batchData.batchCollectionTime }}</small>
        </div>
        <p>DNL专属奶源采集完成</p>
        <p>奶农：{{batchData.bacthCollectionPerson}}</p>
        <p>
          <b-img
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="20"
              class="mr-1"
          />
          <span class="align-bottom">绿色牧场奶源认证书</span>
        </p>
      </app-timeline-item>

      <app-timeline-item
          variant="secondary"
          icon="UserIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>奶粉生产</h6>
          <small class="text-muted">2022年1月20日</small>
        </div>
        <p>新西兰DNL完成奶粉加工装罐</p>
        <p>制奶师：Harry Robert</p>
        <b-media>
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/12-small.png')" />
          </template>
          <h6>Harry Robert</h6>
          <p>Senior Nutritionist</p>
        </b-media>
      </app-timeline-item>

      <!-- FINANCIAL REPORT -->
      <app-timeline-item
          variant="success"
          icon="FileTextIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>出关时间</h6>
          <small class="text-muted">2022年1月22日</small>
        </div>
        <p>您的奶粉已经离开新西兰边境</p>
        <p>清关专员：Smith Row</p>
        <b-button
            v-b-toggle.report-list-with-icon
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
        >
          Show Report
        </b-button>
        <b-collapse id="report-list-with-icon">
          <b-list-group
              flush
              class="mt-1"
          >
            <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span>Last Year's Profit: $20,000</span>
              <feather-icon icon="Share2Icon" />
            </b-list-group-item>
            <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span>This Year's Profit: $25,000</span>
              <feather-icon icon="Share2Icon" />
            </b-list-group-item>
            <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span>Last Year's Commision: $5,000</span>
              <feather-icon icon="Share2Icon" />
            </b-list-group-item>
            <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span>This Year's Commision: $7,000</span>
              <feather-icon icon="Share2Icon" />
            </b-list-group-item>
            <b-list-group-item
                href="#"
                class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span>This Year's Total Balance: $70,000</span>
              <feather-icon icon="Share2Icon" />
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </app-timeline-item>

      <!-- INTERVIEW SCHEDULE -->
      <app-timeline-item
          variant="warning"
          icon="MapPinIcon"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>入关时间</h6>
          <small class="text-muted">2022年1月25日03:00 PM</small>
        </div>
        <p>您的奶粉已经进入中国境内并以通过中国海关检疫检验</p>
        <p>清关专员：刘慈欣</p>
        <p>
          <b-img
              :src="require('@/assets/images/icons/pdf.png')"
              height="auto"
              width="20"
              class="mr-1"
          />
          <span class="align-bottom">检疫检测报告书</span>
        </p>
      </app-timeline-item>

      <!-- ONLINE STORE -->
      <app-timeline-item
          variant="danger"
          icon="ShoppingBagIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>菲乐兰总公司仓库</h6>
          <small class="text-muted">2022年1月26日03:00 PM</small>
        </div>
        <p>您的奶粉已经安全运送到菲乐兰总公司，正发往你所在地区</p>
        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
          <!-- 1st Col -->
          <div class="mb-1 mb-sm-0">
            <span class="text-muted">
            Participants
          </span>
            <b-avatar-group
                size="32px"
                class="mt-50"
            >
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Jenny Looper"
                  :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
              />
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Jennifer Lopez"
                  :src="require('@/assets/images/portrait/small/avatar-s-6.jpg')"
              />
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Arya Stark"
                  :src="require('@/assets/images/portrait/small/avatar-s-7.jpg')"
              />
            </b-avatar-group>
          </div>
          <!-- 2nd Col -->
          <div class="mb-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">预计时间</span>
            <span>2022年1月31日</span>
          </div>
          <!-- 3rd Col -->
          <div>
            <span class="text-muted mb-50 d-block">目的地</span>
            <span>湖南省长沙市新华街家乐福超市</span>
          </div>
        </div>
      </app-timeline-item>

      <!-- DESIGNING UI -->
      <app-timeline-item
          variant="info"
          icon="GridIcon"
      >

        <div class="d-flex align-items-start flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-50">
          <h6>湖南省长沙市新华街家乐福超市</h6>

          <b-badge
              pill
              variant="light-primary"
          >
            经销商
          </b-badge>
          <small class="text-muted">2022年1月31日03:00 PM</small>
        </div>
        <p>您的奶粉移送至湖南省长沙市新华街家乐福超市</p>
        <div>

        </div>
      </app-timeline-item>
      <app-timeline-item
          variant="danger"
          icon="ShoppingBagIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>购买成功：积分 +180</h6>
          <small class="text-muted">2022年2月1日03:00 PM</small>
        </div>
        <p>感谢您对菲乐兰奶粉的信任，您可以通过以下方式联系您的专属营养师</p>
        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">
          <!-- 1st Col -->
          <div class="mb-1 mb-sm-0">
              <span class="text-muted">
              专属营养师
            </span>
            <b-avatar-group
                size="32px"
                class="mt-50"
            >
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Jenny Looper"
                  :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
              />
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Jennifer Lopez"
                  :src="require('@/assets/images/portrait/small/avatar-s-6.jpg')"
              />
              <b-avatar
                  v-b-tooltip.hover
                  class="pull-up"
                  title="Arya Stark"
                  :src="require('@/assets/images/portrait/small/avatar-s-7.jpg')"
              />
            </b-avatar-group>
          </div>
          <!-- 2nd Col -->
          <div class="mb-1 mb-sm-0">
            <span class="text-muted mb-50 d-block">联系电话</span>
            <span>1387601010101</span>
          </div>
          <!-- 3rd Col -->
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BImg, BAvatar, BMedia, BButton, BCard, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ref } from '@vue/composition-api'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      batchData: { },
    }
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t
    }
  },
  async beforeCreate() {
    await useJwt.getData(`tracking/batch/detail/${router.currentRoute.params.id}/`).then(response => {
      this.batchData = response.data
    })
  },
}
</script>
